import { XccEnvironment } from '@xcc-client/services';

// TODO: create new CNAME in so we can make the stage host the stage api
export const environment: XccEnvironment = {
  envMode: 'stage',
  isProduction: false,
  stripeApiPublicKey: 'pk_test_fzmbX1rMaRAS0kMq7XNNYy8f',
  xccApiHost: 'https://checkout-api.ecom-stage.aceable.com',
  xgritApiHostUrl: 'https://stg.xgritapi.com/api/1',
  xgritAuthorization: 'Basic MDY5MGI2MDc4ZjBmNTUwOTJlMmFiZDNlMmY3ODk3Zjg6',
  xgritWebAppUrl: 'https://app-stg.aceable.com/user/signin?b=ACE-PA',
  brand: 'pa',
  domain: '.prepagent.com',
  clientHost: 'https://www.prepagent.com/',
  termsAndConditionsUrl: 'https://www.prepagent.com/terms-and-conditions/',
  recaptchaSiteKey: '6LfQdngjAAAAAI51zzbfB69TEqX60eaw2Sqi-JF2',
  stripeBetas: 'elements_enable_deferred_intent_beta_1',
  stripeApiVersion: '""',
  addPurchaseQueryParam: true,
  ambassador: {
    uid: '',
    isRecordConversionEnabled: false,
  },
  sentryDsn: '',
  passwordConfig: {
    passwordRegEx: '^.{6,}$',
    passwordNotMatchMessage: 'Your password must be at least 6 characters long',
    showPasswordStrength: true,
  },
  temporaryDownurl: 'course-temporarily-unavailable/',
};
